<template>
  <div :class="classes">
    <template
      v-if="
        [
          'instagram-post',
          'linkedin-post',
          'facebook-post',
          'tiktok-post',
          'threads-post',
        ].includes(thread.source)
      "
    >
      <span v-if="thread.instagramPublishingError"
        >This post couldn't be published to Instagram - Message from Instagram:
        <i>{{ thread.instagramPublishingError.message || contactSupportMessages[1] }}</i></span
      >
      <span v-else-if="thread.linkedInPublishingError"
        >This post couldn't be published to LinkedIn - Message from LinkedIn:
        <i>{{ thread.linkedInPublishingError.message || contactSupportMessages[1] }}</i></span
      >
      <span v-else-if="thread.facebookPublishingError"
        >This post couldn't be published to Facebook- Message from Facebook:
        <i>{{ thread.facebookPublishingError.message || contactSupportMessages[1] }}</i></span
      >
      <span v-else-if="thread.tiktokPublishingError"
        >This post couldn't be published to TikTok - Message from TikTok:
        <i>{{ error.message + '. ' + contactSupportMessages[1] }}</i></span
      >

      <span v-else-if="thread.publishingError"><i>{{ thread.publishingError[0].message }}</i></span>
    </template>
    <template v-else>
      <template v-if="error && error.isCustomError && error.source === 'twitter'">
        <span>
          This post couldn't be published to
          <span class="capitalize">{{ error.source }}, {{ error.message }}</span>
        </span>
        <p>Please try again or contact the support (error {{ this.thread.id }})</p>
      </template>

      <div v-else-if="error.source === 'twitter'" :class="{ 'w-full': retry }">
        <span v-if="!isCarouselError">{{ contactSupportMessages[0] }} to Twitter - </span>
        <span v-if="shouldContactSupport" v-html="contactSupportMessages[1]">
          {{ contactSupportMessages[1] }}
        </span>
        <span v-if="thread.type === 'retweet' && thread.couldNotBePostedBecauseNotPremium()">
          Only {{ getPlanLabel('premium') }} users can retweet tweets that aren't theirs.
        </span>
        <span v-if="thread.wasNotPublishedBecauseOfNotACustomer()">
          You don't or didn't have an active subscription.
        </span>
        <span v-if="thread.getTwitterPublishingError()">
          <template v-if="!isCarouselError">
            Message from Twitter:
          </template>
          <i>{{ thread.getTwitterPublishingError() }}</i>
        </span>
      </div>

      <template v-else>
        <span :class="{ 'w-full': retry }">
          This post couldn't be published to <span class="capitalize">{{ error.source }}</span> -
          Message from <span class="capitalize">{{ error.source }}</span
          >:
          <i>{{ error.message }}</i>
        </span>
      </template>
    </template>

    <div v-if="retry && (thread.source !== 'twitter' || !isCarouselError)" class="card-action flex space-x-1 pr-1 mt-2">
      <base-button
        v-if="error.source === 'twitter'"
        data-cy="history-send-to-drafts-button"
        @click="emitSendToDraftsEvent"
        class="bg-transparent hover:bg-transparent focus:bg-transparent text-muted mr-2"
      >
        Send to Drafts
      </base-button>
      <base-button
        v-if="isFacebookAuthenticationError"
        data-cy="history-retry-button"
        class="bg-transparent hover:bg-transparent focus:bg-transparent text-muted"
      >
        <router-link to="/settings#facebook"
          >Connect</router-link
        >
      </base-button>
      <base-button
        v-else-if="isInstagramAuthenticationError"
        data-cy="history-retry-button"
        class="bg-transparent hover:bg-transparent focus:bg-transparent text-muted"
      >
        <router-link to="/settings#instagram"
          >Connect</router-link
        >
      </base-button>
      <base-button
        v-else
        data-cy="history-retry-button"
        @click="emitRetryEvent"
        class="bg-transparent hover:bg-transparent focus:bg-transparent text-muted"
      >
        Retry
      </base-button>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import FacebookMixin from '@/views/Mixins/FacebookMixin';
  import InstagramMixin from '@/views/Mixins/InstagramMixin';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'history-card-error',
    props: {
      thread: {
        type: Object,
      },
      retry: {
        type: Boolean,
        default: false,
      },
      classes: {
        type: String,
        default: '',
      },
      error: {
        type: Object,
      }
    },
    computed: {
      isCarouselError() {
        return (
          lodash.get(this.thread, 'linkedIn.postType') === 'carousel' &&
          this.thread.getTwitterPublishingError() &&
          this.thread.getTwitterPublishingError().includes('Carousel')
        );
      },
      servicesToRetry(){
        return lodash.get(this.thread, 'servicesToRetry', []);
      },
      isFacebookAuthenticationError() {
        const isFacebookError = this.error.source === 'facebook' && this.thread.facebookPublishingError && this.error.code === 190;
        return isFacebookError && !this.isUserConnectedToFacebookWithPage;
      },
      isInstagramAuthenticationError() {
        const isInstagramError = this.error.source === 'instagram' && this.thread.instagramPublishingError && this.error.code === 190;
        return isInstagramError && !this.isUserConnectedToInstagram;
      },
      shouldContactSupport() {
        const isPostRetweetAndUserStandard =
          this.thread.type === 'retweet' && this.thread.couldNotBePostedBecauseNotPremium();
        return (
          isPostRetweetAndUserStandard ||
          (!this.thread.wasNotPublishedBecauseOfNotACustomer() &&
            (!this.thread.publishingError || !this.thread.getTwitterPublishingError()))
        );
      },
      isTweetNotFound() {
        return this.thread.publishingError && this.thread.publishingError[0] && this.thread.publishingError[0].message === '404';
      },
      contactSupportMessages() {
        return this.thread.type === 'retweet'
          ? [
              'This tweet could not be retweeted',
              'Please contact the support for more information.',
            ]
          : [
              "This post couldn't be published",
              `Please try again or contact the support (error ${this.thread.id}).`,
            ];
      },
    },
    methods: {
      emitRetryEvent() {
        this.$emit('retry');
      },
      emitSendToDraftsEvent() {
        this.$emit('send-to-drafts');
      },
    },
    mixins: [FacebookMixin, InstagramMixin, CustomerStatusMixin],
  };
</script>
